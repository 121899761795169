import React from "react";
import { FadeLink } from "./link";


const active = {color: "#191919", fontWeight: "bolder"};

export default function({current}) {
  return (
    <nav className="site-navigation">
      <ul>
        <li>
          <FadeLink style={current.pathname === "/" ? active: undefined} to="/"> About </FadeLink>
        </li>
        <li>
          <FadeLink style={current.pathname === "/blog" ? active: undefined} to="/blog"> Posts </FadeLink>
        </li>
        <li>
          <FadeLink style={current.pathname === "/highlights" ? active: undefined} to="/highlights"> Projects and highlights </FadeLink>
        </li>
      </ul>
    </nav>
  )
}
